import React, {useEffect}  from "react";
import { Header } from "semantic-ui-react";
import { AuthContext } from "../providers/AuthProvider";
import { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BlogDisplay from "./Blog";
import Intro from "./Intro";
import Podcasts from "./Podcasts";
import Reading from "./Reading";
import Logo from "../Logo.JPG"
import YouTube from 'react-youtube';
import Transition from "../transition.png"
import NOSTR from "../nostr-logo-white-on-purple.svg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./Footer";
import Listening from "./Listening";



const Home = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showHomepage, setShowHomepage] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 3000 });
    // If location.state.showContent exists, display homepage content immediately
    if (location.state && location.state.showContent) {
      setShowHomepage(true);
    }
  }, [location.state]);

  const handleContinueClick = () => {
    setShowHomepage(true);
  };

  const videoOptions = {
    width: '2009px',
    height: '1300px',
    aspectRatio: '16:9', // Set the desired aspect ratio (16:9 is a common aspect ratio)
    position: 'absolute',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    playerVars: {
      autoplay: 1,
      frameborder: 0,
      controls: 0,
      showinfo: 0,
      loop: 1,
      mute: 1,
      modestbranding: 0,
      playsinline: 0,
      rel: 0,
    },
  };


  return (
    <div >
    {showHomepage ? (
      <div data-aos="fade" className="homepage" >
        <div className='pageSection4'> 
        <img className='logo-main' src={Logo} alt="Source Node Logo"/>
        {/* <h1 className="font">Welcome, friend.</h1> */}
        </div>
        {/* <img className='divider paddingTop' src={Transition} alt="decorative flourish"/> */}
        {/* {JSON.stringify(auth)} */}
        {/* <p onClick={auth.handleLogin}>Login</p> */}
        {/* <p onClick={auth.handleLogout}>Logout</p> */}
        
        <Intro />
        
        <img className='divider' src={Transition} alt="decorative flourish"/>
        <div className='links'>
        <Reading/>
        {/* <BlogDisplay/> */}
        {/* <div data-aos="fade"> */}
        <Listening/>
        </div>
        <div className='button-a'>
            <Link to="/appearances" className="appearances-btn">Appearances</Link>
        </div>
        {/* </div> */}
        {/* <img className='divider' src={Transition} alt="decorative flourish"/> */}
        {/* <Link to="/protected">Protected</Link>
        <Link to="/public">Public</Link> */}
        {/* <p onClick={()=>navigate("/public")}>navigate to public</p> */}
        <Podcasts/>

        <Footer/>
      </div>
    ) : (
      <div className="matrix-video app">
          <video
            // ref={'https://res.cloudinary.com/dkkwdvec2/video/upload/v1692113828/My_Movie_1_tknfcn.mov'}
            src={
           'https://res.cloudinary.com/dkkwdvec2/video/upload/v1692113828/My_Movie_1_tknfcn.mov'
            }
            loop
            autoPlay
            muted
            style={{
              height: '100vh',
              width: '100vw',
              position: 'absolute',
              objectFit: 'cover',
              /* support for plugin https://github.com/bfred-it/object-fit-images */
              fontFamily: 'object-fit: cover',
              top: 0,
              left: 0,
              backgroundRepeat: 'no-repeat',
              zIndex: 0,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          />
          {/* <YouTube videoId="liFjsSDj1eM" opts={videoOptions} style={{margin: '-100px', position: 'absolute', backgroundColor: 'black', height: '1500px', backgroundSize: 'cover' ,
              backgroundPosition: 'center center',}}/> */}
        
        <div className="popup">
       
          <button class='glowing-btn' onClick={handleContinueClick}><span class='glowing-txt exitFont'>EXIT</span></button>
        </div>
      </div>
    )}
</div>

    // <div className='homepage'>
      

    // </div>
  );
};

export default Home;
